<template>
  <v-container
    fluid
    tag="section"
  >
      <material-card
        color="primary"
        icon="mdi-sale"
      >
        <template #title>
          Додати тип акції
        </template>

        <ButtonComeBack class="ml-3 mb-3" />

          <v-row>
            <v-col cols="12" lg="6">
              <v-form class="form-action-type-add" 
                      lazy-validation 
                      v-model="isValidForm" 
                      ref="formAction">

                <!--Включено-->
                <v-col cols="12">
                  <v-checkbox
                    v-model="enabled"
                    label="Включено"
                  ></v-checkbox>
                </v-col>

                <!--Табы-->
                <v-col cols="12" show-arrows>
                  <v-tabs v-model="tab">
                    <v-tab v-for="local in localization" 
                      :key="local.lang_key">
                      {{ local.name }}
                    </v-tab>
                  </v-tabs>
                </v-col>

                <!--Содержымое табов-->
                <v-col cols="12">
                  <v-tabs-items v-model="tab">
                    <v-tab-item 
                      v-for="(item, index) in actionType" 
                      :key="item.lang_key" 
                      :eager="true">
                      <template>

                        <!--Название-->
                        <v-text-field
                          label="Назва"
                          v-model="item.name"
                          required
                          :rules="[v => !!v || errorRequired]"
                        />
                        <!--Картинка-->
                        <ImageUpload 
                          :imageBaseUrl="imageBaseUrl" 
                          @image-upload:change="changeImageList($event, item.lang_key)" 
                        />
                      </template>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
                
                <!--Кнопки-->
                <v-col cols="12" class="text-right">
                  <v-btn 
                    :disabled="isActionTypeSuccess"
                    color="primary" 
                    min-width="150" 
                    @click="createAction()">
                    Зберегти
                  </v-btn>
                </v-col>
                <Preloader v-if="isSending" />
              </v-form>
              
            </v-col>
          </v-row>
      </material-card>
  </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { IMAGE_BASE_URL } from '@/env.config';
 
export default {
  name: 'PageActionTypeAdd',
  data() {
    return {
      // Состояние акции 
      enabled: false,
      // Активный таб
      tab: 0,
      // Типы акций
      actionType: [],
      // Нужно vuetify для валидации формы
      isValidForm: true,
      // Сообщение об ошибке для обязательных полей
      errorRequired: `Це поле обов'язкове`,
      // Указывает на то что выполняется отправка формы
      isSending: false,
      // Изображения для отправки 
      imagesToSend: {},
      // Указывает на то что создание типа акций прошло удачно (чтобы при повторном нажатии на кнопку не созвался тип акции)
      isActionTypeSuccess: false,
      // Базовый url  картинки
      imageBaseUrl: `${IMAGE_BASE_URL}actions/action_type/`
    }
  },
  computed: {
    ...mapGetters(['snackbar', 'localization'])
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapMutations(['setSnackbar']),
    ...mapActions(['getLocalization']),
    // Подготовка данных
    async init() {
      // Получить локализации
      await this.getLocalization();
      this.actionType = this.localization.map((el) => {
        return {
          name: '',
          lang_key: el.lang_key
        };
      });
    },

    // Изменить список картинок 
    changeImageList(data, langKey) {
      if (data.action) {
        this.imagesToSend[langKey] = {
          sendImage: data.sendImage
        };
      } else {
        delete this.imagesToSend[langKey];
      }
    },

    // Создать список ошибок
    createListErrors() {
      let errors = '';
      for(let item of this.actionType) {
        if (!item.name) {
          errors += `Помилка у мові ${item.lang_key}</br>`;
        }
      }
      return errors;
    },

    // Создание типа акций
    async createAction() {
      if (this.isSending && !this.isActionTypeSuccess) return;

      if (!this.$refs.formAction.validate()) {
        this.setSnackbar({ type: 'error', text: this.createListErrors() });
        return;
      }

      this.isSending = true;

      try {
        let { data } = await HTTP.post('/create-action-type', {
          enabled: this.enabled ? 1 : 0,
          langs: this.actionType
        });

        if (!data?.content?.id) throw Error("");

        let isImages = Object.keys(this.imagesToSend).length;
        this.isActionTypeSuccess = true;

        this.setSnackbar({ 
          type: 'success', 
          text: `Тип акції створено. ${ isImages ? 'Виконується завантаження зображень.': '' }` 
        });

        if (!isImages) {
          this.isSending = false;
        }
        // сделал через $watch потомучто setSnackbar сделан через "mutation" (не может вернуть промис)
        const unwatch = this.$watch('snackbar.model', function (newVal, oldVal) {
          unwatch();
          // Если нет изображений переходим на страницу акции, если есть ожидаем загрузки
          if (!isImages) {
            this.goToActionTypePage(data.content.id);
          } else {
            this.runProcessImagesSend(data.content.id);
          }
        });
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
        this.isSending = false;
      }
    },

    // Запускает процесс отравки изображений, отправкой занимается "ImageUpload"
    async runProcessImagesSend(actionTypeId) {
      let errors = '';
      for(let key in this.imagesToSend) {
        let item = this.imagesToSend[key];
        let queryParams = {
          lang_key: key,
          object_image_id: actionTypeId,
          image_type: 'action_type_image',
        };
        let result = await item.sendImage(queryParams);
        // Если при загрузке изображений были - показать попап
        if (!result.error) {
          delete this.imagesToSend[key];
        } else {
          errors += `${result.error}</br>`;
          this.setSnackbar({ type: 'error', text: errors });

          // Редирект при закрытии попапа "setSnackbar $watch" 
          const unwatch = this.$watch('snackbar.model', function (newVal, oldVal) {
            unwatch();
            this.goToActionTypePage(actionTypeId);
          });
        }
      }

      this.isSending = false;

      // Если при загрузке изображений небыло ошибок - редирект
      if (!errors) {
        this.goToActionTypePage(actionTypeId);
      }
    },

    // Редирект на страницу типа акции
    goToActionTypePage(promoutionId) {
      this.$router.push(`/action-type/${promoutionId}/`);
    }
  },
}
</script>

<style scoped lang="sass">
.form-action-type-add
  position: relative
</style>
